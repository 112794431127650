
import { defineComponent } from "@vue/runtime-core";
import AppPage from "@/components/AppPage.vue";
import DataTable from "@/components/DataTable.vue";
import Daterange from "@/components/Daterange.vue";
import SubsetInput from "@/components/SubsetInput.vue";
import { Option, Pagination, Sorting } from "@/use/types";
import TextInput from "@/components/TextInput.vue";

import moment, { Moment } from "moment-timezone";
import axios from "axios";

interface IDataModel {
  loading: boolean;
  filter: IQuery;
  rows: any[];
  totalRows: number | null;
  columns: Column[];
  grouping: Array<string>;
  options: {
    affiliates: Option[];
    advertisers: Option[];
    currencies: Option[];
    affiliates_type: Option[];
  };
  definition: any;
  totals: any[];
  mainGroupOptions: any[];
}

interface ComputedModel {
  secondaryGroupOptions: Array<Column>;
  effectiveColumns: Array<Column>;
}

interface MethodsModel {
  formatDate(d: string): string;
  setGrouping(grouping_type: string, value: string): void;
  load(): void;
  clear(): void;
  filterOptions(collection: string, query?: string): void;
  getCurrencies(): void;
  getAffiliates(): void;
  getAdvertisers(): void;
  updateCurrencyIds(e: any): void;
  updateAdvertiserIds(e: any): void
  updateAffiliateIds(e: any): void;
  updateAffiliateTypeId(e: any): void;
  getMaxPeriod(): void;
  setPeriod(value: any): void;
}

interface IQuery {
  period: Moment[];
  search: string;
  pagination: null | Pagination;
  sorting: Sorting;
  affiliate_ids: number[];
  advertiser_ids: number[];
  currency_id: string[];
  affiliate_type_id: number[];
}

interface Column {
  id: string;
  name: string;
  selected: boolean;
  freezed?: boolean;
  group?: number;
}

export function getDefaultQuery(): IQuery {
  return {
    period: [],
    search: "",
    sorting: { column: "created_at", direction: "desc" },
    pagination: { limit: 10, offset: 0 },
    affiliate_ids: [],
    advertiser_ids: [],
    currency_id: ["USD"],
    affiliate_type_id: [2],
  };
}

const dataColumns = [
  {
    id: "created_at",
    name: "Дата",
    selected: true,
    sortable: true,
    freezed: true,
    group: 1,
  },
  {
    id: "affiliate_id",
    name: "ID веба",
    selected: false,
    sortable: true,
    freezed: true,
    group: 1,
  },
  {
    id: "type",
    name: "Тип веба",
    selected: false,
    sortable: false,
    freezed: true,
    group: 1,
  },
  {
    id: "advertiser",
    name: "Рекламодатель",
    selected: false,
    sortable: false,
    searchable: true,
    group: 1,
  },
  {
    id: "payout",
    name: "Начислено",
    selected: true,
    sortable: true,
    htmlClass: "finance-column",
  },
  {
    id: "withheld",
    name: "Удержано",
    selected: true,
    sortable: true,
    htmlClass: "finance-column",
  },
  {
    id: "income",
    name: "Перечислено вебу",
    selected: true,
    sortable: true,
    freezed: true,
    htmlClass: "finance-column",
  },
];

const groupingFields = ["created_at", "affiliate_id", "advertiser", "type"];

function getGroupingColumns() {
  return dataColumns.filter((x) => x.group === 1);
}

function storeGrouping(grouping: Array<string | null>) {
  localStorage.setItem("fin-report.grouping", JSON.stringify(grouping));
}

function getStorageGroupParams() {
  const json = localStorage.getItem("fin-report.grouping");

  if (!json) {
    return ["created_at", ""];
  } else {
    return JSON.parse(json);
  }
}

export default defineComponent<IDataModel & ComputedModel & MethodsModel>({
  components: {
    AppPage,
    DataTable,
    Daterange,
    SubsetInput,
    TextInput,
  },
  data(): IDataModel {
    return {
      loading: false,
      rows: [],
      columns: dataColumns,
      totalRows: null,
      grouping: getStorageGroupParams(),
      options: {
        affiliates: [],
        advertisers: [],
        currencies: [],
        affiliates_type: [
          { id: 0, name: "Внешний" },
          { id: 1, name: "Внутренний" },
          { id: 2, name: "Все типы" },
        ],
      },
      filter: getDefaultQuery(),
      definition: {
        shortcut: null,
        timezone: "Europe/Kiev",
      },
      totals: [],
      mainGroupOptions: getGroupingColumns(),
    };
  },
  mounted: function () {
    this.$watch("filter.not_published", this.load);
    this.$watch("filter.pagination", this.load);
    this.$watch("filter.sorting", this.load);
  },
  async created() {
    this.load();
    this.getAdvertisers();
    this.getAffiliates();
    this.getCurrencies();
    await this.getMaxPeriod();
  },
  computed: {
    secondaryGroupOptions() {
      return getGroupingColumns().filter(
        (x: any) => x.id !== this.grouping[0]
      );
    },
    effectiveColumns() {
      return this.columns.map((c: Column) => ({
        ...c,
        selected: (this.grouping.includes(c.id) && c.group === 1) || !c.group,
      }));
    },
  },
  methods: {
    formatDate(d: string): string {
      return moment(d).format("DD.MM.YYYY");
    },
    setGrouping(grouping_type: string, value: string) {
      if (value === this.grouping[1]) {
        this.grouping[1] = this.grouping[0];
      }

      if (value === this.grouping[0]) {
        this.grouping[0] = this.grouping[1];
      }

      if (grouping_type === "main") {
        this.grouping[0] = value;
      } else {
        this.grouping[1] = value;
      }

      this.columns.map((c: Column) => {
        if (c.group === 1) {
          if (c.id === this.grouping[0] || c.id === this.grouping[1]) {
            c.selected = true;
          } else {
            c.selected = false;
          }
        }
      });

      storeGrouping(this.grouping);
      this.load();
    },
    async getCurrencies() {
      const { data } = await axios.get("/api/finance-report/currencies-lookup");
      this.options.currencies = data;
    },
    async getAffiliates() {
      const { data } = await axios.get("/api/affiliates/lookup");
      this.options.affiliates = data;
    },
    async getAdvertisers() {
      const { data } = await axios.get("/api/advertisers/lookup");
      this.options.advertisers = data;
    },
    updateCurrencyIds(e: any) {
      this.filter.currency_id = e;
    },
    updateAdvertiserIds(e: any) {
      this.filter.advertiser_ids = e;
    },
    updateAffiliateIds(e: any) {
      this.filter.affiliate_ids = e;
    },
    updateAffiliateTypeId(e: any) {
      this.filter.affiliate_type_id = e;
    },
    async getMaxPeriod() {
      const { data } = await axios("/api/get-period-limits/conversions");
      this.filter.period = [
        data.min || moment().endOf("day"),
        moment().endOf("day"),
      ];
    },
    async filterOptions(collection: string, query = "", field?: string) {
      const { data } = await axios.get("/api/" + collection + "/lookup", {
        params: { q: JSON.stringify({ query }) },
      });
      const key: "affiliates" | "advertisers" = (field || collection) as any;
      this.options[key] = data;
    },
    async clear() {
      this.filter = getDefaultQuery();
      storeGrouping(["created_at", ""]);
      await this.getMaxPeriod();
      this.load();
    },
    async setPeriod(value: any) {
      if (!value.length) {
        await this.getMaxPeriod();
        return;
      }
      this.filter.period = value;
    },
    async load() {
      this.loading = true;
      this.rows = [];
      this.totalRows = 0;
      this.totals = [];
      this.grouping = getStorageGroupParams();

      try {
        const { data } = await axios.get("/api/finance-report", {
          params: {
            q: JSON.stringify({
              ...this.filter,
              affiliate_type_id: Number(this.filter.affiliate_type_id.join()),
              grouping: this.grouping,
            }),
          },
        });
        this.rows = data.rows;
        this.totalRows = data.total;
        this.totals = data.totals;
      } catch (e) {
        this.$router.push(`/error/${(e.response && e.response.status) || 500}`);
      }

      this.loading = false;
    },
  },
});
